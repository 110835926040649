<script>
    const creditsPromise = (async () => {
        const response = await self.fetch("generated/manual_credits.json");
        return await response.json();
    })();
    const ossCreditsPromise = (async () => {
        const response = await self.fetch("generated/oss_credits.json");
        return await response.json();
    })();
</script>

<div on:mouseenter on:mouseleave>
{#await creditsPromise}
{:then credits}
    <h3 class="credits-title">Acknowledgements</h3>
    {#each Object.entries(credits) as [section, names]}
        <h4 class="section">{section}</h4>
        {#each Object.entries(names) as [name, acks]}
            <div class="credit">
                <span class="titles">{@html acks.map((a) => a.title).join("<br>")}</span>
                <span class="author">{name}</span>
            </div>
        {/each}
    {/each}
{/await}
{#await ossCreditsPromise}
{:then credits}
    <h3 class="credits-title">Open source libraries attributions<br>(Automatically generated)</h3>
    {#each Object.entries(credits) as [license, authors]}
        <h4 class="section">{license}</h4>
        {#each Object.entries(authors) as [author, libraries]}
            <div class="credit">
                <span class="titles">{@html libraries.map((l) => l.title).join("<br>")}</span>
                <span class="author">{author}</span>
            </div>
        {/each}
    {/each}
{/await}
</div>

<style>
    .credits-title {
        margin-bottom: 0;
    }

    .credits-title,
    .section {
        width: 100%;
        text-align: center;
    }

    .credit {
        font-size: 0.75em;
        display: flex;
        width: 100%;
        gap: 0.5em;
        margin-bottom: 1em;
    }

    .titles,
    .author {
        flex-grow: 1;
        width: 100%;
    }

    .titles {
        text-align: right;
    }

    .author {
        font-weight: bold;
        font-family: "Fira Small Caps", "Fira Sans Condensed", sans-serif;
        font-variant: small-caps;
    }
</style>
