<script>
    import {modalProps} from "../stores.js";
    import {onDestroy} from "svelte";

    import Container from "./Container.svelte";
    import Navigation from "./Navigation.svelte";
    import Notes from "./Notes.svelte";
    import Flipper from "./Flipper.svelte";

    let modal;
    let visible = false;
    let index;
    let platform;
    let covers = [];

    const unsubscribe = modalProps.subscribe((props) => {
        if (props.visible) {
            ({index, platform, covers} = props);
            visible = true;
        }
    });

    $: index = Math.min(Math.max(index, 0), covers.length - 1);

    onDestroy(() => {
        unsubscribe();
    });

    let flipper;
    let flipped = false;

    function closeModal() {
        visible = false;
        flipped = false;
    }

    function closeModalCallback(event) {
        if (event.target === modal || (flipped && event.target === flipper)) {
            closeModal();
        }
    }
</script>

{#if visible}
    <div class="modal" bind:this={modal} on:click={closeModalCallback}>

        <Navigation bind:index={index} max={covers.length - 1} {closeModal} />

        <Flipper bind:flipper={flipper} bind:flipped={flipped} let:flip>
            <Container slot="front"
                {covers} {index} {platform} {closeModal} {flip} flipped={false}
            />
            <Notes slot="back"
                title={platform === "Rack" ? "Game Rack" : covers[index].game.name}
                titleHash={covers[index]["title_hash"]}
                {platform} {closeModal} {flip} flipped={true}
            />
        </Flipper>

    </div>
{/if}

<style>
    .modal {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: fixed;
        z-index: 1;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        perspective: calc(3 * var(--modal-width));
    }
</style>
