<script>
    import {realisticCovers} from "./persistentStores.js";

    export let cover;
    let loaded = false;

    $: realistic = $realisticCovers && cover.filename_3d;
    $: gameStyle = realistic ? "": "margin-bottom: var(--hm);";
    $: placeholderStyle = realistic ? "": `background: ${cover.placeholder_color};`;
</script>

<div
    id={cover["title_hash"]}
    class="game"
    on:click
    style={`width: ${cover.width * 4}px; height: ${cover.height * 4}px; ${placeholderStyle} ${gameStyle}`}
>
    <img
        loading="lazy"
        src={realistic ? cover.filename_3d : cover.filename}
        class:loaded
        width={cover.width * 4}
        on:load={() => loaded = true}
    />
</div>

<style>
    .game {
        transition: transform 0.3s ease-in-out, background 1s ease-out;
    }

    .game:hover {
        transform: scale(1.03);
        filter: drop-shadow(0 0 10px black);
    }

    img {
        opacity: 0;
        transition: opacity 1s ease-out;
    }

    img.loaded {
        opacity: 1;
    }
</style>
