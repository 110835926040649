<script>
    import {platformLogos} from "./stores.js";
    import {darkTheme} from "./persistentStores.js";

    export let platform;
</script>

<div class="footer">
    <img src={$platformLogos[platform][$darkTheme ? "wide_dark" : "wide"]} alt="{platform} logo" />
</div>

<style>
    .footer {
        position: sticky;
        bottom: 0;
        background: linear-gradient(0deg, var(--color-footer-bg-0) 50%, var(--color-footer-bg-1) 100%);
        height: var(--footer-height);
        width: calc(100%);
        display: flex;
        justify-content: center;
        align-items: flex-end;
        margin-left: calc(-1 * var(--m) / 2);
        padding-left: calc(var(--m) / 2);
        margin-right: calc(-1 * var(--m) / 2);
        padding-right: calc(var(--m) / 2);
        z-index: 3;
        transition: background 10.25s ease;
    }

    .footer img {
        height: calc(0.4 * var(--footer-height) - var(--m));
        transition: filter, opacity 0.3s ease-in-out;
        filter: var(--filter-column-footer);
        opacity: 0.3;
        margin-bottom: var(--m);
    }
</style>
