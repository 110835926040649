<script>
    import {
        darkTheme,
        realisticCovers,
        preferredRegionOrder,
        preferencesFirst,
        hideScrollButtons,
        reducedAnimations,
    } from "./persistentStores.js";

    import sliders from "./resources/icons/sliders.svg";
    import command from "./resources/icons/command.svg";

    import Toggle from "./ui/Toggle.svelte";
    import Kbd from "./ui/Kbd.svelte";
    import DragList from "./ui/DragList.svelte";

    function scroll() {
        window.scrollTo({
            left: $preferencesFirst ? 100000000 : 0,
            behavior: $reducedAnimations ? "auto" : "smooth",
        });
    }
</script>

<div class="settings">
    <h2>Preferences {@html sliders}</h2>
    <Toggle label="Dark mode" bind:checked={$darkTheme} />
    <Toggle label="Realistic covers" bind:checked={$realisticCovers} />
    <Toggle label="Reduced animations" bind:checked={$reducedAnimations} />
    <Toggle label="Rows mode" disabled />
    <Toggle label="Hide scroll buttons" bind:checked={$hideScrollButtons} />
    <Toggle label="Preferences first" bind:checked={$preferencesFirst} on:click={scroll} />
    <div class="label">Preferred region order</div>
    <DragList list={preferredRegionOrder} />
    <div class="label disabled">Import notes</div>
    <div class="label disabled">Export notes</div>
    <h2>Keyboard shortcuts {@html command}</h2>
    <Kbd label="(Focused view) Previous game" keys={["left"]} />
    <Kbd label="(Focused view) Next game" keys={["right"]} />
    <Kbd label="(Focused view) Exit info view" keys={["Esc"]} />
</div>

<style>
    .settings {
        margin-top: 0.4em;
        margin-bottom: auto;
        padding: 1em;
        height: 100%;
        width: 90%;
        border-radius: 0.7em;
        background-color: var(--color-settings-window-bg);
        user-select: none;
    }

    .settings > :global(div),
    .settings > :global(label) {
        margin-bottom: 0.5em;
    }

    .settings h2 {
        display: flex;
        justify-content: space-between;
    }

    .settings h2 :global(svg) {
        margin-top: 0.2em;
        margin-left: 0.5em;
        height: 0.8em;
    }

    .settings h2:first-child {
        margin-top: 0;
    }

    .settings > :global(.disabled) {
        opacity: 0.25;
        cursor: not-allowed;
    }

    .settings > :global(.disabled *) {
        cursor: not-allowed !important;
    }

    .settings > :global(.list) {
        margin: 1em 0;
    }
</style>
