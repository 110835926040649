<script>
    import {
        darkTheme,
        realisticCovers,
        preferencesFirst,
        hideScrollButtons,
        reducedAnimations,
    } from "./persistentStores.js";

    import chevronLeft from "./resources/icons/chevron-left.svg";
    import chevronsLeft from "./resources/icons/chevrons-left.svg";
    import chevronRight from "./resources/icons/chevron-right.svg";
    import chevronsRight from "./resources/icons/chevrons-right.svg";
    import github from "./resources/icons/github.svg";
    import moon from "./resources/icons/moon.svg";
    import rack from "./resources/rack/rack_horizontal_lockup_dynamic.svg";
    import sliders from "./resources/icons/sliders.svg";
    import sun from "./resources/icons/sun.svg";
    import box from "./resources/icons/box.svg";
    import hexagon from "./resources/icons/hexagon.svg";

    let animationRequest;

    function continuouslyScroll(offset) {
        cancelScroll();
        animationRequest = requestAnimationFrame(function animate(time) {
            window.scrollBy(offset, 0);
            animationRequest = requestAnimationFrame(animate);
        });
    }

    function cancelScroll() {
        if (animationRequest) {
            cancelAnimationFrame(animationRequest);
        }
    }

    function scrollToSettings() {
        window.scrollTo({
            left: $preferencesFirst ? 0 : 100000000,
            behavior: $reducedAnimations ? "auto" : "smooth",
        });
    }
</script>

<div class="toolbar" style="--height: calc(var(--toolbar) - var(--m));">
    <div class="logo">{@html rack}</div>
    {#if !$hideScrollButtons}
        <div class="scrollers">
            <span on:mouseenter={() => continuouslyScroll(-25)} on:mouseleave={() => cancelScroll()}>
                {@html chevronsLeft}
            </span>
            <span on:mouseenter={() => continuouslyScroll(-7)} on:mouseleave={() => cancelScroll()}>
                {@html chevronLeft}
            </span>
            <span on:mouseenter={() => continuouslyScroll(7)} on:mouseleave={() => cancelScroll()}>
                {@html chevronRight}
            </span>
            <span on:mouseenter={() => continuouslyScroll(25)} on:mouseleave={() => cancelScroll()}>
                {@html chevronsRight}
            </span>
        </div>
    {/if}
    <div class="icons">
        <span on:click={() => $darkTheme = !$darkTheme}>
            {@html $darkTheme ? sun : moon}
        </span>
        <span on:click={() => $realisticCovers = !$realisticCovers}>
            {@html $realisticCovers ? hexagon : box}
        </span>
        <span on:click={scrollToSettings}>{@html sliders}</span>
        <a href="https://github.com/Tenchi2xh/Rack">
            <span>{@html github}</span>
        </a>
    </div>
</div>

<style>
    .toolbar {
        position: fixed;
        top: 0;
        left: 0;
        width: calc(100vw - 2 * var(--m));
        height: var(--height);
        padding: var(--m);
        display: flex;
        justify-content: space-between;
    }

    .logo :global(svg) {
        height: var(--height);
        width: unset;
    }

    .toolbar > div {
        user-select: none;
    }

    .toolbar :global(svg) {
        height: var(--height);
        padding: 0.3em;
        box-sizing: border-box;
        opacity: 0.5;
        cursor: pointer;
    }

    .toolbar :global(svg):hover {
        opacity: 1;
    }
</style>
