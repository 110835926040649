import Rack from "./Rack.svelte";

const app = new Rack({
    target: document.body,
    props: {
        payloadPath: "generated/payload.json",
    },
});

export default app;
