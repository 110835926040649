<script>
    export let flipped = false;
    export let flipper = undefined;

    function flip() {
        flipped = !flipped;
    }
</script>

<div bind:this={flipper} class="flipper" class:flipped={flipped}>
    <slot name="front" {flip}></slot>
    <slot name="back" {flip}></slot>
</div>

<style>
    .flipper {
        transition: transform 0.8s;
        transform-style: preserve-3d;
    }

    .flipper > :global(*) {
        transform-style: preserve-3d;
        backface-visibility: hidden;
    }

    .flipper > :global(:nth-child(2)) {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotateY(180deg);
    }

    .flipper.flipped {
        transform: rotateY(180deg);
    }
</style>
