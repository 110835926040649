<script>
    import {reducedAnimations, realisticCovers} from "../persistentStores.js";
    import {platformLogos} from "../stores.js";

    import {scale} from "svelte/transition";
    import {cubicOut} from "svelte/easing";

    import Infos from "./Infos.svelte";
    import Buttons from "./Buttons.svelte";

    const rackImage = "resources/rack/rack_vertical_lockup_dark_bg.svg";

    export let covers = [];
    export let index = 0;
    export let platform = "";
    export let closeModal;
    export let flip;
    export let flipped;

    $: cover = covers[index];
    $: game = cover.game;
    $: platformLogo = $platformLogos[platform].square;

    $: if (cover) {
        scrollToGame(cover["title_hash"]);
    }

    // Transition parameters
    const contentDuration = 1000;
    const contentScale = {
        start: 0.5,
        duration: contentDuration,
        easing: cubicOut,
    };

    function scrollToGame(titleHash) {
        const cover = document.getElementById(titleHash);
        cover.scrollIntoView({
            block: "center",
            inline: "center",
            behavior: $reducedAnimations ? "auto" : "smooth",
        });
    }
</script>

<!-- TODO: in and out -->
<div class="container" in:scale={contentScale}>
    <div class="content" style={`--modal-logo: url(${platformLogo})`}>
        <Buttons {closeModal} {flip} {flipped} />

        <div class="columns">
            <img class="image" src={platform === "Rack" ? rackImage : game.image} />
            <Infos {game} {platform} />
        </div>
    </div>

    <div class="spine">
        <img src={$realisticCovers && cover.filename_3d ? cover.filename_3d : cover.filename} />
    </div>
</div>

<style>
    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .content {
        background-color: var(--color-modal-bg);
        border-radius: 0.7em;
        padding: 1em;
        width: var(--modal-width);
        box-shadow: 0 0 30px black;
        position: relative;
        z-index: 1;
    }

    .content::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: var(--modal-logo);
        opacity: var(--opacity-modal-logo);
        filter: var(--filter-modal-logo);
        background-size: auto 5em;
        background-repeat: no-repeat;
        background-position-x: calc(100% - 0.5em);
        background-position-y: calc(100% - 0.5em);
        z-index: -1;
    }

    .columns {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
    }

    .image {
        width: 100%;
        border-radius: 0.5em;
    }

    .spine {
        display: flex;
        filter: drop-shadow(0 0 15px black);
        width: var(--modal-width);
        margin-top: 30px;
    }

    .spine img {
        width: 100%;
    }
</style>
