import {writable} from "svelte/store";

function persistentStore(name, initialValue) {
    const storedValue = JSON.parse(localStorage.getItem(name));
    const store = writable(storedValue === null ? initialValue : storedValue);
    store.subscribe((value) => {
        localStorage.setItem(name, JSON.stringify(value));
    });

    window.addEventListener("storage", () => {
        const storedValue = JSON.parse(localStorage.getItem(name));
        if (storedValue === null) {
            return;
        }
        store.set(storedValue);
    });

    return store;
}

const systemDarkTheme = (
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches
);

export const preferredRegionOrder = persistentStore(
    "preferredRegionOrder", ["PAL", "NTSC-U", "NTSC-J"],
);

export const darkTheme = persistentStore("darkTheme", systemDarkTheme);
export const realisticCovers = persistentStore("realisticCovers", false);
export const notes = persistentStore("notes", {});
export const preferencesFirst = persistentStore("preferencesFirst", false);
export const hideScrollButtons = persistentStore("hideScrollButtons", false);
export const reducedAnimations = persistentStore("reducedAnimations", false);
