<script>
    import Definition from "./Definition.svelte";

    export let game;
</script>

<dl>
    <Definition title="Released on" list={game["released_on"]} />
    <Definition title="Developer" pluralize={true} list={game["developers"]} />
    <Definition title="Publisher" pluralize={true} list={game["publishers"]} />
    <Definition title="You played it on" list={game["played_on"]} />
    <Definition title="Your rating" rating={game["rating"]} />
    <Definition title="Finished" finished={game["finished"]} />
</dl>

<style>
    dl {
        display: inline-block;
        margin: auto 0;
    }
</style>
