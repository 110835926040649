<script>
    export let label;
    export let keys;

    function iconize(name) {
        const icon = {
            "left": "resources/icons/arrow-left.svg",
            "right": "resources/icons/arrow-right.svg",
        }[name];

        if (!icon) {
            return name;
        }
        return `<img src="${icon}" />`;
    }

    const keysMarkup = keys.map((k) => `<kbd>${iconize(k)}</kbd>`).join(" + ");
</script>

<div class="kbd">
    <span class="label">{label}</span>
    <div>{@html keysMarkup}</div>
</div>

<style>
    .kbd {
        display: flex;
        justify-content: space-between;
    }

    :global(kbd) {
        font-size: 0.8em;
        display: inline-block;
        padding: 0.1em 0.4em;
        border-radius: 0.3em;
        border: 1px solid #cccccc;
        color: #333333;
        font-family: unset;
        box-shadow: 0 2px 0 #cccccc;
        background: white;
    }

    :global(kbd img) {
        height: 0.8em;
        margin-top: 0.1em;
        margin-bottom: -0.1em;
    }
</style>
