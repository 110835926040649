<script>
    import starFull from "../resources/icons/star-full.svg";
    import star from "../resources/icons/star.svg";
    import checkSquare from "../resources/icons/check-square.svg";
    import square from "../resources/icons/square.svg";

    export let title;
    export let pluralize = false;
    export let list = [];
    export let rating = undefined;
    export let finished = undefined;

    const plural = (pluralize && list.length > 1) ? "s" : "";

    function limitList(list) {
        if (list.length <= 5) return list;
        return [...list.slice(0, 5), "..."];
    }
</script>

<dt>{title}{plural}</dt>

{#if list.length > 0}
    <dd>
        {#if list.length > 1}
            <ul>
                {#each limitList(list) as elem}
                    <li>{elem}</li>
                {/each}
            </ul>
        {:else}
            {list[0]}
        {/if}
    </dd>

{:else if rating !== undefined}
    <dd class="rating">
        {@html starFull.repeat(rating) + star.repeat(5 - rating)}
    </dd>

{:else if finished !== undefined}
    <dd class="finished">
        {@html finished ? checkSquare : square}
    </dd>

{/if}

<style>
    dt {
        float: left;
        clear: left;
        width: 130px;
        text-align: right;
        font-weight: 700;
    }

    dt::after {
        content: ":";
    }

    dd ul {
        padding-left: 1.35em;
        margin: 0;
    }

    dd {
        margin: 0 0 0 140px;
        padding: 0 0 0.5em 0;
    }

    :global(.rating svg),
    :global(.finished svg) {
        margin-top: 0.2em;
        height: 0.8em;
        width: unset;
    }
</style>
