export function getPreferredCover(preferredRegionOrder, coverSet) {
    for (const region of preferredRegionOrder) {
        for (const cover of coverSet) {
            if (cover.region === region) {
                return cover;
            }
        }
    }
    return coverSet[0];
}

function isNumber(string) {
    return /^-?\d+\.?\d*$/.test(string);
}

function shortenWord(word) {
    return isNumber(word) ? word : word[0];
}

function acronymize(string) {
    return string.trim().split(" ").map(shortenWord).join("");
}

export function shortenName(name, maxLength) {
    if (name.length < maxLength) {
        return name;
    }
    if (!name.includes("-") && !name.includes(":")) {
        return acronymize(name);
    }
    const delimiter = name.includes("-") ? "-" : ":";
    const index = name.indexOf(delimiter);
    const [left, right] = [name.substring(0, index), name.substring(index + 1)];
    const result0 = left + delimiter + " " + acronymize(right);
    if (result0.length < maxLength) {
        return result0;
    }
    return acronymize(left) + delimiter + " " + acronymize(right);
}

// TODO: Shigoto font has no accents for some reason
export function sanitize(string) {
    return string.replace("é", "e");
}
