<script>
    import mail from "../resources/icons/mail.svg";
    import feather from "../resources/icons/feather.svg";

    import DefinitionsList from "./DefinitionsList.svelte";
    import Credits from "./Credits.svelte";

    export let game;
    export let platform;

    function findReleaseDate(game) {
        let releaseDate = game["release_date"];
        for (const release of game["releases"]) {
            if (release["platform"] === platform && release["release_date"]) {
                releaseDate = release["release_date"];
                break;
            }
        }
        if (!releaseDate) {
            return "(Unknown release date)";
        }
        // Parse date (some have times in them) and format to yyyy-mm-dd
        return new Date(releaseDate).toISOString().split("T")[0];
    }

    let infoScroll;
    let animationRequest;

    function continuouslyScrollDown(offset) {
        cancelScroll();
        animationRequest = requestAnimationFrame(function animate(time) {
            infoScroll.scrollBy(0, offset);
            animationRequest = requestAnimationFrame(animate);
        });
    }

    function cancelScroll() {
        if (animationRequest) {
            cancelAnimationFrame(animationRequest);
        }
    }

    // Don't want to store it in plain text in the source
    const contact = [..."nbjmup;ufodijAufbn3yi/ofu"]
        .map((c) => String.fromCharCode(c.charCodeAt(0) - 1))
        .join("");
</script>

{#if platform !== "Rack"}
    <div class="infos">
        <div class="infos-top">
            <h1 class="title">{@html game.name.replace(": ", ":<br>")}</h1>
            <span class="date">{findReleaseDate(game)}</span>
            <p class="desc">{game["short_description"] || "(No description)"}</p>
        </div>
        <DefinitionsList {game} />
    </div>
{:else}
    <div class="infos scroll" bind:this={infoScroll}>
        <div class="infos-top">
            <h1 class="title">Game Rack</h1>
            <span class="date">By Tenchi</span>
            <p><em>First prototyped on 2021-09-04</em></p>
            <p>Game Rack is a virtual shelf for your games: experience your history with video games in a more physical way.</p>
            <p>How to contact me: <a href={contact}>{@html mail}</a></p>
            <p>I'd like to mention a few of the tools that I really enjoyed using for this project:</p>
            <ul>
                <li><strong>Svelte</strong> &mdash; <em>by far</em> the best experience I've had with front-end so far.</li>
                <li><strong>Keras</strong> &mdash; made ML really easy to get going.</li>
                <li><strong>makefun</strong> &mdash; enabled for some crazy Python meta-programming.</li>
                <li><strong>Feather {@html feather}</strong> &mdash; really clean icons, straight to the point website, no fluff.</li>
                <li><strong>pre-commit</strong> &mdash; first time trying, keeping it forever.</li>
            </ul>
        </div>
        <Credits on:mouseenter={() => continuouslyScrollDown(1)} on:mouseleave={() => cancelScroll()} />
    </div>
{/if}

<style>
    .infos {
        padding-left: 1em;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        align-items: center;
    }

    .infos.scroll {
        max-height: 40em;
        overflow-y: scroll;
        scrollbar-width: none;
    }

    .infos.scroll::-webkit-scrollbar {
        display: none;
    }

    .infos-top {
        width: 100%;
    }

    .title {
        width: 95%;
        margin: 0;
    }

    .date {
        font-style: italic;
    }

    .desc {
        text-align: justify;
    }

    .infos-top :global(svg) {
        width: 1em;
        height: unset;
        margin-bottom: -0.15em;
    }
</style>
