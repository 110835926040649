<script>
    export let label;
    export let checked = false;
    export let disabled = false;
</script>

<label class="switch" class:disabled={disabled} on:click>
    <span class="label">{label}</span>
    <input type="checkbox" bind:checked {disabled} />
    <span class="slider" />
</label>

<style>
    .switch {
        position: relative;
        display: flex;
        justify-content: space-between;
    }

    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    .slider {
        --padding: 2px;
        --height: 20px;

        width: calc(var(--height) * 2);
        height: var(--height);
        margin-left: 1em;
        position: relative;
        cursor: pointer;
        background-color: #cccccc;
        transition: background-color 0.4s;
        border-radius: var(--height);
    }

    .slider::before {
        position: absolute;
        content: "";
        width: calc(var(--height) - var(--padding) * 2);
        height: calc(var(--height) - var(--padding) * 2);
        left: var(--padding);
        top: var(--padding);
        background-color: var(--color-toggle-slider);
        transition: transform 0.4s;
        border-radius: calc(var(--height) - var(--padding));
    }

    input:checked + .slider {
        background-color: var(--color-accent);
    }

    input:checked + .slider::before {
        transform: translateX(var(--height));
    }
</style>
