import {writable} from "svelte/store";

export const platforms = writable(undefined);
export const platformLogos = writable({});
export const platformShortNames = writable({});
export const rack = writable({});
export const modalProps = writable({
    visible: false,
    index: 0,
    platform: "",
    covers: [],
});
