<!-- Inspired by https://svelte.dev/repl/3bf15c868aa94743b5f1487369378cf3?version=3.21.0 -->

<script>
    import alignJustify from "../resources/icons/align-justify.svg";
    import {flip} from "svelte/animate";

    export let list;
    export let disabled = false;

    let hovering = false;
    let dragged;

    function drop(event, target) {
        event.dataTransfer.dropEffect = "move";
        const start = parseInt(event.dataTransfer.getData("text/plain"));
        const tempList = $list;

        if (start < target) {
            tempList.splice(target + 1, 0, tempList[start]);
            tempList.splice(start, 1);
        } else {
            tempList.splice(target, 0, tempList[start]);
            tempList.splice(start + 1, 1);
        }
        $list = tempList;
        hovering = null;
    }

    function dragstart(event, i) {
        dragged = i;
        event.dataTransfer.effectAllowed = "move";
        event.dataTransfer.dropEffect = "move";
        event.dataTransfer.setData("text/plain", i);
    }

    function conditionalFlip(node, {from, to}, {index, ...params}) {
        if (index === dragged) {
            return {};
        }
        return flip(node, {from, to}, params);
    }

</script>

<div class="list" class:disabled={disabled} >
    {#each $list as elem, index (elem)}
        <div
             class="item"
             class:active={hovering === index}
             animate:conditionalFlip={{index: index, duration: 500}}

             draggable={!disabled}
             on:dragstart={(event) => dragstart(event, index)}
             on:dragenter={() => hovering = index}
             ondragover="return false"
             on:drop|preventDefault={(event) => drop(event, index)}
        >
            <span>{elem}</span>
            <span class="icon">{@html alignJustify}</span>
        </div>
    {/each}
</div>

<style>
    .list {
        border-radius: 0.5em;
        border: 1px solid #dbdbdb;
    }

    .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.5em 1em;
        cursor: grab;
    }

    .item:active {
        cursor: grabbing;
    }

    .item:not(:last-child) {
        border-bottom: 1px solid #dbdbdb;
    }

    .item.active {
        background-color: var(--color-accent);
        color: #ffffff;
    }

    .icon {
        color: #dbdbdb;
    }

    .icon :global(svg) {
        margin-bottom: -0.2em;
        height: 1.25em;
        width: unset;
    }
</style>
