<script>
    import x from "../resources/icons/x.svg";
    import edit from "../resources/icons/edit.svg";
    import info from "../resources/icons/info.svg";

    export let closeModal;
    export let flip;
    export let flipped;
</script>

<div class="icons">
    <span on:click={closeModal}>{@html x}</span>
    <span on:click={flip}>{@html flipped ? info : edit}</span>
</div>

<style>
    .icons {
        position: absolute;
        right: 0.5em;
        top: 0.5em;
        user-select: none;
        display: flex;
        flex-direction: column;
    }

    .icons :global(svg) {
        width: 1em;
        height: unset;
        opacity: 0.5;
        cursor: pointer;
        text-decoration: none;
        font-family: sans-serif;
    }

    .icons :global(svg):hover,
    .icons :global(svg):focus {
        opacity: 1;
    }
</style>
