<script>
    import {platforms, modalProps, rack} from "./stores.js";
    import {preferredRegionOrder} from "./persistentStores.js";
    import {getPreferredCover} from "./util.js";

    import Spine from "./Spine.svelte";
    import Settings from "./Settings.svelte";
    import Footer from "./ColumnFooter.svelte";

    export let platform;

    $: coverSets = platform == "Rack" ? [[$rack]] : $platforms[platform];
    $: covers = coverSets.map((cs) => getPreferredCover($preferredRegionOrder, cs));

    function modalCallback(i) {
        $modalProps = {
            visible: true,
            platform,
            covers,
            index: i,
        };
    }
</script>

<div class="container">
    <div class={`column${platform === "Rack" ? " settings" : ""}`}>
        {#each covers as cover, i}
            <Spine
                {cover}
                on:click={(e) => modalCallback(i)}
            />
        {/each}
        {#if platform === "Rack"}
            <Settings />
        {/if}
    </div>
    <Footer {platform} />
</div>

<style>
    .container {
        height: var(--column-height);
        overflow-y: scroll;
        min-width: min-content;
        position: relative;
        scrollbar-width: none;

        /*
        Let the elements render outside of their column when they are zoomed in
        Hack source: https://stackoverflow.com/a/19717472
        */
        padding-left: 2em;
        margin-left: -2em;
        padding-right: 2em;
        margin-right: -2em;
        overflow-x: hidden;
    }

    .container::-webkit-scrollbar {
        display: none;
    }

    .container:last-child {
        padding-right: var(--m);
    }

    .column {
        display: flex;
        flex-direction: column;
        align-items: center;
        min-height: calc(var(--column-height) - var(--footer-height));
    }

    .column.settings {
        background-color: var(--color-settings-bg);
        min-height: var(--column-height);
        padding-bottom: var(--footer-height);
        margin-bottom: calc(-1 * var(--footer-height));
        transition: background 0.33s ease;
    }

    .container .column:hover + :global(.footer img) {
        filter: unset;
        opacity: unset;
    }
</style>
