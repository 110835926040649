<script>
    import {notes} from "../persistentStores.js";
    import {platformShortNames} from "../stores.js";
    import Buttons from "./Buttons.svelte";
    import {shortenName, sanitize} from "../util.js";

    export let title;
    export let titleHash;
    export let platform;
    export let closeModal;
    export let flip;
    export let flipped;

    const placeholder = (
        "You can write some notes here.\n" +
        "For example:\n\n" +
        "How did you discover this game?\n" +
        "How was the experience?\n" +
        "Just an old memory for later"
    );

    // https://svelte.dev/repl/f55e23d0bf4b43b1a221cf8b88ef9904?version=3.12.1
    let debounceTimer;
    function debounce(value) {
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => {
            $notes[titleHash] = value;
        }, 500);
    }
</script>

<div class="note-paper">
    <Buttons {closeModal} {flip} {flipped} />
    <div class="top"></div>
    <div class="header">
        <div class="title">{sanitize(shortenName(title, 28))}</div>
        <div class="platform">{$platformShortNames[platform].toUpperCase()}</div>
    </div>
    <textarea
        value={$notes[titleHash] || ""}
        on:keyup={({target: {value}}) => debounce(value)}
        placeholder={placeholder}
    ></textarea>
</div>

<style>
    .note-paper {
        display: flex;
        flex-direction: column;
        height: calc(0.75 * var(--modal-width) / 1.4142135623730951);
        width: calc(0.75 * var(--modal-width));
        color: #0000a0;
        background-color: white;
        background-image: url("/resources/misc/paper.jpg");
        background-size: 50%;
        font-size: 2em;
        font-family: "Shigoto", cursive;
        line-height: 1;
        box-shadow: 0 0 30px black;

        /* Irregular pattern to make it more sheet-like */
        border-top-left-radius: 3px 255px;
        border-top-right-radius: 3px 225px;
        border-bottom-right-radius: 225px 3px;
        border-bottom-left-radius: 255px 3px;
    }

    .note-paper :global(.icons) {
        font-size: 0.5em;
    }

    .note-paper textarea {
        flex-grow: 1;
        margin: 0;
        padding: 0.5em;
        padding-top: 0.2em;
        width: calc(100% - 1em);
        resize: none;
        overflow-y: auto;
        color: #0000a0;
        font-family: "Shigoto", cursive;
        font-size: 1em;
        border-style: none;
        border-color: transparent;
        outline: none;
        background: none;
        background-image: linear-gradient(rgba(0, 0, 255, 0.35) 1px, transparent 0);
        background-size: 100% calc(1em + 1px);
        background-attachment: local;
    }

    .top {
        pointer-events: none;
        position: absolute;
        top: 1.9em;
        left: 0;
        width: 100%;
        height: 0.1em;
        background-image: linear-gradient(rgba(180, 0, 0, 0.5) 1px, transparent 0);
        background-size: 100% 1.9em;
    }

    .header {
        padding: 0.5em;
        margin-right: 1em;
        margin-bottom: 0.37em;
        display: flex;
        justify-content: space-between;
    }

    .title {
        margin-top: 0.6em;
        margin-right: auto;
        padding: 0 0.2em;
        border-radius: 0.8em 0.3em;
        background-image:
            linear-gradient(
                to right,
                rgba(228, 252, 90, 0.1),
                rgba(228, 252, 90, 0.7) 4%,
                rgba(228, 252, 90, 0.4)
            );
    }

    .platform {
        margin-bottom: 0.7em;
        font-size: 65%;
        color: #a00000;
        padding: 0.35em;
        border: solid 1px #a00000;
        border-top-left-radius: 255px 15px;
        border-top-right-radius: 15px 225px;
        border-bottom-right-radius: 225px 15px;
        border-bottom-left-radius: 15px 255px;
    }

</style>
