<script>
    import chevronLeft from "../resources/icons/chevron-left.svg";
    import chevronRight from "../resources/icons/chevron-right.svg";
    import {fly} from "svelte/transition";

    export let index;
    export let max;
    export let closeModal;

    let leftTrigger = {};
    let rightTrigger = {};

    function navigateLeft() {
        leftTrigger = {};
        index -= 1;
    }

    function navigateRight() {
        rightTrigger = {};
        index += 1;
    }

    function handleKeydown(event) {
        switch (event.key) {
        case "Escape":
            closeModal();
            break;
        case "ArrowLeft":
            navigateLeft();
            break;
        case "ArrowRight":
            navigateRight();
            break;
        default:
            return;
        }
        event.preventDefault();
    }
</script>

<svelte:window on:keydown={handleKeydown}/>

<div class="prev" on:click={navigateLeft} style={index <= 0 ? "display: none;" : ""}>
    {#key leftTrigger}
        <span in:fly={{x: -50}}>{@html chevronLeft}</span>
    {/key}
</div>
<div class="next" on:click={navigateRight} style={index >= max ? "display: none;" : ""}>
    {#key rightTrigger}
        <span in:fly={{x: 50}}>{@html chevronRight}</span>
    {/key}
</div>

<style>
    .prev,
    .next {
        position: absolute;
        top: 0;
        height: 100%;
        color: white;
        opacity: 0.5;
        width: calc(0.7 * (100% - var(--modal-width)) / 2);
        background: rgba(255, 0, 255, 0.2);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        user-select: none;
    }

    .prev :global(svg),
    .next :global(svg) {
        height: 100px;
        width: unset;
    }

    .prev {
        left: 0;
        background: linear-gradient(90deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%);
    }

    .next {
        right: 0;
        background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
    }

    .prev:hover,
    .prev:focus,
    .next:hover,
    .next:focus {
        opacity: 1;
    }
</style>
