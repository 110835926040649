<script>
    import {darkTheme, preferencesFirst, reducedAnimations} from "./persistentStores.js";
    import {platforms, platformLogos, platformShortNames, rack} from "./stores.js";

    import {onMount} from "svelte";
    import Modal from "./modal/Modal.svelte";
    import Column from "./Column.svelte";
    import Toolbar from "./Toolbar.svelte";

    export let payloadPath;

    let root;

    onMount(() => {
        root = document.documentElement;
    });

    $: if (root) {
        root.classList.toggle("dark", $darkTheme);
    }

    $: if (root) {
        root.classList.toggle("reduced-animations", $reducedAnimations);
    }

    (async () => {
        const response = await self.fetch(payloadPath);
        const payload = await response.json();

        for (const coverSets of Object.values(payload.platforms)) {
            coverSets.forEach(
                (cs) => cs.forEach(
                    (c) => {
                        c["game"] = payload.metadata[c["title_hash"]];
                    },
                ),
            );
        }

        $platformLogos = payload["platform_logos"];
        $platformShortNames = payload["platform_short_names"];
        $rack = payload["rack"];
        $platforms = payload.platforms;
    })();
</script>


{#if !$platforms}
    <!-- TODO: Loading spinner and logo? "in" transition for rack -->
    <p>Fetching...</p>

{:else}
    <Modal />
    <div class="rack">
        {#if $preferencesFirst}
            <Column platform="Rack"/>
        {/if}
        {#each Object.keys($platforms) as platform}
            <Column {platform}/>
        {/each}
        {#if !$preferencesFirst}
            <Column platform="Rack"/>
        {/if}
    </div>
    <Toolbar />
{/if}

<style>
    .rack {
        display: flex;
        gap: var(--m);
        transition: filter 0.25s ease;
    }

    :global(.toolbar) {
        transition: filter 0.25s ease;
    }

    :global(.modal) ~ .rack {
        filter: blur(5px);
    }

    :global(.modal) ~ :global(.toolbar) {
        filter: blur(5px);
    }
</style>
